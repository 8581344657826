import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

// constants
import { COMPANY_NAME, COMPANY_PHONE } from 'lib/constants/companyInformation'

const AccountFooter = props => {
  const year = new Date().getFullYear()

  return (
    <Box width='100%' align='center' p={2} mt={4} color='white' bgcolor='black'>
      <Typography color='common.white' variant='body1' gutterBottom>
        Get in touch with your{' '}
        <Link
          color='common.white'
          href='https://steveandkatescamp.com/contact/'
          target='_blank'
        >
          local director
        </Link>
        {', '} or reach our main office{' '}
        <Link
          color='common.white'
          href='mailto:letschat@steveandkate.com?subject=I have a question'
        >
          by email
        </Link>{' '}
        or phone: {COMPANY_PHONE}
      </Typography>
      <Typography variant='body1'>
        Copyright &copy; {year}{' '}
        <Link
          color='common.white'
          target='_blank'
          href='https://steveandkatescamp.com/'
        >
          {COMPANY_NAME}
        </Link>
      </Typography>
    </Box>
  )
}

export default AccountFooter
