import React from 'react'

import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'

// helpers
import { fieldErrorMessages } from 'lib/helpers/forms'

const FormControlWithErrorHelper = props => {
  const { errorsObject, inputErrors, errorField, children, ...other } = props

  const errorMessages = fieldErrorMessages({
    errors: errorsObject,
    inputErrors,
    field: errorField
  })

  return (
    <FormControl
      fullWidth
      margin='normal'
      error={!!errorMessages.length}
      {...other}
    >
      {children}
      {errorMessages.map(message => (
        <FormHelperText key={message}>{message}</FormHelperText>
      ))}
    </FormControl>
  )
}

export default FormControlWithErrorHelper
