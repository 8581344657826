import React, { useEffect } from 'react'

const withQueue = WrappedComponent => {
  return props => {
    const {
      notifications, // via redux state
      clearNotification, // via redux actions
      // NOTE: `open` and `closed` are both `false` during fade-out transition!
      open, // via withState
      setOpen, // via withState
      closed, // via withState
      setClosed, // via withState
      notification, // via withState
      setNotification // via withState
    } = props

    // See https://material-ui.com/components/snackbars/#consecutive-snackbars
    const processQueue = () => {
      // Check for notifications in the Redux queue.
      if (notifications.length > 0) {
        // If fully closed, set current `notification` state to the oldest Redux
        // notification and open it.
        if (closed) {
          setNotification(notifications[0])
          setOpen(true)
          setClosed(false)
        }

        // If open and there's a new notification, close immediately. The next
        // notification in the queue will then open.
        if (open && notifications.length > 1) {
          clearNotification(notification)
          setOpen(false)
        }
      }
    }

    // make sure we always reprocess the queue after we render
    useEffect(processQueue)

    if (!notification) {
      return null
    }

    return <WrappedComponent processQueue={processQueue} {...props} />
  }
}

export default withQueue
