import React from 'react'

const withEventHandlers = WrappedComponent => {
  return props => {
    const {
      loginForm, // via redux state
      setLoginFormField, // via redux actions
      logInAccount // via withMutation
    } = props

    const handleChangeFormField = fieldName => event =>
      setLoginFormField(fieldName, event.target.value)

    const handleToggleCheckbox = fieldName => event => {
      setLoginFormField(fieldName, String(event.target.checked))
    }

    const handleClickLogIn = e => {
      e.preventDefault()

      const { email, password, rememberMe } = loginForm

      logInAccount({
        variables: {
          input: {
            email,
            password,
            rememberMe: String(rememberMe) === 'true'
          }
        }
      })
    }

    return (
      <WrappedComponent
        onChangeFormField={handleChangeFormField}
        onToggleCheckbox={handleToggleCheckbox}
        onClickLogIn={handleClickLogIn}
        {...props}
      />
    )
  }
}

export default withEventHandlers
