import React from 'react'

// material ui
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const NotFound = props => {
  return (
    <Box my={4}>
      <Typography variant='h3' sx={{ textAlign: 'center', my: 2 }}>
        Oops, we couldn't find that page.
      </Typography>

      {Object.keys(props).length > 0 && (
        <Typography variant='body1' sx={{ textAlign: 'center', my: 2 }}>
          {JSON.stringify(props)}
        </Typography>
      )}
    </Box>
  )
}

export default NotFound
