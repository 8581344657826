import { SET_ERROR_MESSAGE } from 'actions/account/actionTypes'

const errorMessage = (state = {}, action) => {
  switch (action.type) {
    case SET_ERROR_MESSAGE:
      const { message, variant } = action
      return Object.assign({}, state, { message, variant })
    default:
      return state
  }
}

export default errorMessage
