import React from 'react'

// material-ui
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Hidden from '@mui/material/Hidden'

// styles
import { styled } from '@mui/material/styles'
import { compose, spacing, sizing, positions } from '@mui/system'

// components
import Typography from '@mui/material/Typography'

// logo
import Skc from 'uno/images/svg/skc-text.svg'

const TextLogo = styled(Skc)(compose(spacing, sizing, positions))

const MobileHeader = props => {
  const { title, nav, greeting, logout } = props

  return (
    <>
      <Box width='100%' align='center' color='black' bgcolor='white'>
        <TextLogo maxWidth='30%' mt='1rem' mb='1rem' />
        <Typography
          variant='body1'
          fontWeight='fontWeightMedium'
          position='absolute'
          right='10px'
          top='12px'
        >
          {greeting || (logout && <Link href='/logout'>Log out</Link>)}
        </Typography>
      </Box>
      <Box
        width='100%'
        align='center'
        color='white'
        bgcolor='black'
        position='relative'
      >
        <Box position='absolute' left='10px' top='8px'>
          {nav}
        </Box>
        <Typography
          variant='body1'
          fontWeight='fontWeightMedium'
          align='center'
          p='1rem'
        >
          {title}
        </Typography>
      </Box>
    </>
  )
}

export default MobileHeader
