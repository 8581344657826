import React from 'react'

import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import TextField from '@mui/material/TextField'
import FormHelperText from '@mui/material/FormHelperText'

import { fieldErrorMessages } from 'lib/helpers/forms'

const FormTextFieldWithValidation = props => {
  const {
    label,
    form,
    field,
    type,
    onChange,
    onKeyPress,
    errors, // legacy errors object
    inputErrors, // raw inputErrors from GraphQL response
    required,
    multiline,
    children,
    disabled
  } = props

  const errorMessages = fieldErrorMessages({ errors, inputErrors, field })

  return (
    <FormControl
      fullWidth
      margin='normal'
      error={errorMessages.length}
      required={required}
    >
      <FormLabel htmlFor={field}>{label}</FormLabel>
      {children}
      <TextField
        id={field}
        value={form[field] || ''}
        onChange={onChange(field)}
        onKeyPress={onKeyPress}
        type={type || 'text'}
        multiline={multiline}
        variant={multiline ? 'outlined' : 'standard'}
        disabled={!!disabled}
      />
      {errorMessages.map(message => (
        <FormHelperText key={message}>{message}</FormHelperText>
      ))}
    </FormControl>
  )
}

export default FormTextFieldWithValidation
