import { compose } from 'redux'

// higher order components
import withRedux from './withRedux'
import withState from './withState'
import withEventHandlers from './withEventHandlers'

/**
Provides general purpose success and error handling for forms that perform
GraphQL mutations. This is a higher-order function that takes a configuration
object as its argument, and returns a new higher-order component based on that
configuration.
@param {String} mutation - name of the mutation that will be performed
@param {String} field - name of the field that will be returned for a successful mutation.
@param {String} redirect - optional path for redirect on success.
@param {String} successMessage - optional message to display as notification on success.
@return {Function} - a higher-order component that provides onMutationError and onMutationCompleted handlers.
**/
const withFormResults =
  ({ mutation, field, redirect, successMessage, onSuccess }) =>
  WrappedComponent => {
    return compose(
      withRedux,
      withState,
      withEventHandlers({
        mutation,
        field,
        redirect,
        successMessage,
        onSuccess
      })
    )(WrappedComponent)
  }

export default withFormResults
