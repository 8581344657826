import React from 'react'

// material ui
import Typography from '@mui/material/Typography'

// components
import ErrorMessageUI from 'components/shared/ErrorMessage/UI'

const Login = props => {
  const { notice } = props
  const { message, variant, body } = notice

  return (
    <ErrorMessageUI message={message} variant={variant}>
      <Typography my={1}>{body}</Typography>
    </ErrorMessageUI>
  )
}

export default Login
