import { compose } from 'redux'

// higher-order components
import withRedux from './withRedux'
import withEventHandlers from './withEventHandlers'

// presentational component
import UI from './UI'

export default compose(withRedux, withEventHandlers)(UI)
