import { SET_MODAL } from 'actions/account/actionTypes'

const modal = (state = {}, action) => {
  switch (action.type) {
    case SET_MODAL:
      return Object.assign({}, state, {
        id: action.id === undefined ? state.id : action.id,
        open: action.open === undefined ? state.open : action.open
      })
    default:
      return state
  }
}

export default modal
