import React from 'react'
import PropTypes from 'prop-types'

// material ui
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'

// components
import Typography from '@mui/material/Typography'

// see also components/atoms/NotificationContent.js
const VARIANTS = {
  success: {
    color: 'common.white',
    bgcolor: 'secondary.main'
  },
  error: {
    color: 'common.white',
    bgcolor: 'error.dark'
  },
  info: {
    color: 'common.white',
    bgcolor: 'primary.main'
  },
  warning: {
    color: 'text.primary',
    bgcolor: 'warning.main'
  }
}

const ErrorMessage = props => {
  const {
    message, // via withRedux (or passed in directly)
    variant, // passed withRedux (or passed in directly)
    onClickDismiss, // via withEventHandlers
    children // passed in directly
  } = props

  if (!message) return null

  const variantProps = VARIANTS[variant] || VARIANTS.error

  return (
    <Box
      p={4}
      my={2}
      align='center'
      color={variantProps.color}
      bgcolor={variantProps.bgcolor}
      position='relative'
    >
      <Typography variant='h4'>{message}</Typography>
      {children}
      {onClickDismiss && (
        <Box position='absolute' right={0} top={0}>
          <IconButton onClick={onClickDismiss} size='large'>
            <HighlightOffIcon width='3rem' htmlColor='white' />
          </IconButton>
        </Box>
      )}
    </Box>
  )
}

ErrorMessage.propTypes = {
  message: PropTypes.string,
  onClickDismiss: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'error', 'info', 'warning']),
  children: PropTypes.node
}

export default ErrorMessage
