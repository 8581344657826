import React from 'react'

// components
import DesktopHeader from 'components/shared/LogoHeader/DesktopHeader'

const DesktopBanner = props => {
  const { title } = props

  return <DesktopHeader variant='h3' title={title} />
}

export default DesktopBanner
