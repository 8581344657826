import React from 'react'

// apollo
import { ApolloProvider } from '@apollo/client'
import { client } from 'lib/apollo'

// redux
import { Provider as ReduxProvider } from 'react-redux'
import store from 'lib/stores/login/redux'

// themes
import unoTheme from 'lib/themes/unoTheme'
import parentTheme from 'lib/themes/parentTheme'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'

// shared components
import AppErrorBoundary from 'components/shared/AppErrorBoundary'

// local components
import Routes from './Routes'

// date picker
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

const App = props => {
  const {
    basename,
    honeybadgerApiKey,
    environment,
    headerMessage,
    notice,
    bhParam,
    bucCaseNumber
  } = props

  return (
    <ReduxProvider store={store}>
      <ApolloProvider client={client}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <ThemeProvider theme={unoTheme}>
            <CssBaseline />
            <ThemeProvider theme={parentTheme}>
              <AppErrorBoundary
                honeybadgerApiKey={honeybadgerApiKey}
                environment={environment}
              >
                <Routes
                  basename={basename}
                  headerMessage={headerMessage}
                  notice={notice}
                  bucCaseNumber={bucCaseNumber}
                  bhParam={bhParam}
                />
              </AppErrorBoundary>
            </ThemeProvider>
          </ThemeProvider>
        </LocalizationProvider>
      </ApolloProvider>
    </ReduxProvider>
  )
}

export default App
