import React from 'react'

// material ui
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

// components
import LogoHeader from 'components/shared/LogoHeader'
import Typography from '@mui/material/Typography'

const UI = ({ error }) => (
  <>
    <LogoHeader title='Oops.' />
    <Box m={4} align='center'>
      <Typography variant='h4'>We make big mistakes.</Typography>
      <Typography m={2} variant='body1'>
        Happily, we can learn from unexpected errors. Until we can resolve this
        one, please reload or restart.
      </Typography>
      <Box mt={4}>
        <Button href='/' variant='contained'>
          Take me home
        </Button>
      </Box>
      <Typography m={2} variant='subtitle1'>
        {error.message}
      </Typography>
    </Box>
  </>
)

export default UI
