import { createStore, applyMiddleware } from 'redux'
import reducer from 'reducers/login'
import logger from 'lib/stores/logger'

const initialState = {
  modal: { id: null, open: false },
  errorMessage: null,
  notifications: [],
  forms: {
    loginForm: {
      email: '',
      password: '',
      rememberMe: false
    }
  }
}

const middleware = []
if (process.env.NODE_ENV === 'development') {
  middleware.push(logger)
}

const store = createStore(reducer, initialState, applyMiddleware(...middleware))

export default store
