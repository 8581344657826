import React from 'react'

// material ui
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

const PopupNotifications = props => {
  const { open, onClose, onExited, notification } = props
  const { variant = 'success', autoHide = true, message } = notification
  const disableClickAway = { mouseEvent: false }

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHide ? 6000 : null}
      onClose={onClose}
      ClickAwayListenerProps={disableClickAway}
      TransitionProps={{ onExited }}
    >
      <Alert onClose={onClose} severity={variant} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  )
}

export default PopupNotifications
