import React from 'react'
import Honeybadger from 'honeybadger-js'
import { useNavigate } from 'react-router-dom'

// components
import { scrollToTop } from 'components/shared/ScrollToTop'

function parseMutationResults({ data, mutation, field }) {
  // get array of raw InputErrors (if any)
  const inputErrors = (data && data[mutation] && data[mutation].errors) || []

  // *DEPRECATED* - use inputErrors instead - each attribute can have more than one error.
  // transform an array of raw InputErrors into an object whose keys are
  // attributes that produced validation errors
  const formErrors = {}
  inputErrors.forEach(inputError => {
    if (inputError.path && inputError.path[0] === 'attributes') {
      formErrors[inputError.path[1]] = inputError.message
    }
  })

  // get the returned field (if any)
  const fieldResult = data && data[mutation] && data[mutation][field]

  return { inputErrors, formErrors, fieldResult }
}

const withEventHandlers =
  ({ mutation, field, redirect, successMessage, onSuccess }) =>
  WrappedComponent => {
    return props => {
      const {
        setFormErrors, // via withState
        setInputErrors, // via withState
        notify, // via redux actions
        setErrorMessage // via redux actions
      } = props

      const navigate = useNavigate()

      const handleMutationCompleted = data => {
        // parse the raw mutation results
        const { inputErrors, formErrors, fieldResult } = parseMutationResults({
          data,
          mutation,
          field
        })

        // if mutation was not successful, set error state and return
        if (inputErrors.length) {
          const errorMessage =
            formErrors.base || 'Please fix the errors highlighted on the form.'
          setErrorMessage(errorMessage)
          setInputErrors(inputErrors)
          setFormErrors(formErrors)
          scrollToTop()
          return { inputErrors, formErrors }
        }

        // if mutation was successful, clear any errors and show a success message
        setErrorMessage(null)
        setInputErrors([])

        if (successMessage) {
          notify({ message: successMessage })
        }

        if (redirect) {
          navigate(redirect)
        }

        if (onSuccess) {
          onSuccess(data, props)
        }

        return { inputErrors, formErrors, fieldResult }
      }

      const handleMutationError = error => {
        notify({
          variant: 'error',
          autoHide: false,
          message: 'Something went wrong. Please try again or refresh.'
        })
        // https://docs.honeybadger.io/lib/javascript/guides/reporting-errors.html
        Honeybadger.notify(error, {
          name: `FormMutationError: ${mutation}`,
          context: { mutation, field }
        })
        console.log('FormMutationError', error)
      }

      return (
        <WrappedComponent
          onMutationCompleted={handleMutationCompleted}
          onMutationError={handleMutationError}
          redirect={redirect}
          {...props}
        />
      )
    }
  }

export default withEventHandlers
