import React from 'react'

const withEventHandlers = WrappedComponent => {
  return props => {
    const {
      setErrorMessage // from redux actions
    } = props

    const handleClickDismiss = e => {
      e.preventDefault()
      setErrorMessage(null)
    }

    return <WrappedComponent onClickDismiss={handleClickDismiss} {...props} />
  }
}

export default withEventHandlers
