import React, { useState } from 'react'

const withState = WrappedComponent => {
  return props => {
    // raw InputError array from GraphQL mutation result
    const [inputErrors, setInputErrors] = useState([])

    // object whose keys are the attributes with errors
    const [formErrors, setFormErrors] = useState({})

    return (
      <WrappedComponent
        formErrors={formErrors}
        setFormErrors={setFormErrors}
        inputErrors={inputErrors}
        setInputErrors={setInputErrors}
        {...props}
      />
    )
  }
}

export default withState
