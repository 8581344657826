import React from 'react'

// GraphQL
import { useMutation } from '@apollo/client'
import mutation from './mutation.graphql'

const withMutation = WrappedComponent => {
  return props => {
    const {
      onMutationCompleted, // via withFormResults
      onMutationError, // via withFormResults
      setLoggedIn // via withState
    } = props

    /**
    @private
    Adds additional handling to the standard `onMutationCompleted` provided by
    `withFormResults`:
    * sets "loggedIn" state to true to disable log-in button while redirecting
    **/
    const handleMutationCompleted = data => {
      const { fieldResult: account } = onMutationCompleted(data)

      if (account) {
        setLoggedIn(true)
      }
    }

    // setup GraphQL mutation
    const [logInAccount, { loading: mutationLoading }] = useMutation(mutation, {
      onCompleted: handleMutationCompleted,
      onError: onMutationError
    })

    return (
      <WrappedComponent
        logInAccount={logInAccount}
        mutationLoading={mutationLoading}
        {...props}
      />
    )
  }
}

export default withMutation
