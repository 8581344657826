import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

// shared components
import PopupNotifications from 'components/shared/PopupNotifications'
import NotFound from 'components/shared/NotFound'
import AccountFooter from 'components/shared/AccountFooter'

// components for routes
import Login from 'components/login/Login'

// local components
import Banner from './Banner'

const LoginRoutes = ({
  basename,
  headerMessage,
  notice,
  bhParam,
  bucCaseNumber
}) => (
  <BrowserRouter basename={basename}>
    <Banner headerMessage={headerMessage} />
    <PopupNotifications />
    <Routes>
      <Route
        path='/'
        element={
          <Login
            notice={notice}
            bhParam={bhParam}
            bucCaseNumber={bucCaseNumber}
          />
        }
      />
      <Route path='*' component={NotFound} />
    </Routes>
    <AccountFooter />
  </BrowserRouter>
)

export default LoginRoutes
