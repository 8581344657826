import { cloneDeepWith } from 'lodash/lang'

// trim leading and trailing whitespace from all string values
const trimValues = form => {
  if (!form) return null

  const trimmer = value => {
    if (typeof value == 'string') {
      return value.trim()
    }
  }

  return cloneDeepWith(form, trimmer)
}

// return an array of error messages for the given field
const fieldErrorMessages = ({ field, inputErrors, errors }) => {
  const fieldErrors = inputErrors?.filter(
    error => error.path[0] === 'attributes' && error.path[1] === field
  )
  if (fieldErrors?.length) {
    return fieldErrors.map(error => error.message)
  }

  const legacyErrors = errors && errors[field]
  if (legacyErrors) {
    return [legacyErrors]
  }

  return []
}

export { trimValues, fieldErrorMessages }
