import React from 'react'

import Box from '@mui/material/Box'

// components
import Typography from '@mui/material/Typography'

const LegacyBackupCareHeader = props => (
  <Box my={2}>
    <Typography my={2} align='center' variant='h4'>
      Log in or create an account to redeem the Bright Horizons passcodes you
      received via email from Steve & Kate's.
    </Typography>
  </Box>
)

export default LegacyBackupCareHeader
