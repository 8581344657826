import { NOTIFY, CLEAR_NOTIFICATION } from 'actions/account/actionTypes'

// add the new notification to the end of the queue (FIFO)
const notify = (state, action) => {
  const { type, ...notification } = action
  notification.id = new Date().getTime() // milliseconds
  return [...state, notification]
}

// remove any notifications with the same id
const clearNotification = (state, action) => {
  return state.filter(notification => notification.id !== action.id)
}

const reducer = (state = {}, action) => {
  switch (action.type) {
    case NOTIFY:
      return notify(state, action)
    case CLEAR_NOTIFICATION:
      return clearNotification(state, action)
    default:
      return state
  }
}

export default reducer
