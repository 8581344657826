import { SET_LOGIN_FORM, SET_LOGIN_FORM_FIELD } from './actionTypes'

export const setLoginForm = loginForm => {
  return {
    type: SET_LOGIN_FORM,
    loginForm
  }
}

export const setLoginFormField = (field, value) => {
  return {
    type: SET_LOGIN_FORM_FIELD,
    field,
    value
  }
}
