import React from 'react'
import Honeybadger from 'honeybadger-js'

import UI from './UI'

export default class AppErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    const { honeybadgerApiKey, environment } = props
    this.state = { error: null }
    Honeybadger.configure({
      apiKey: honeybadgerApiKey,
      environment: environment,
      disabled: environment === 'local'
    })
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error: error })
    console.log('AppErrorBoundary', error, errorInfo)
    Honeybadger.notify(error, {
      name: 'AppErrorBoundary',
      context: { errorInfo }
    })
  }

  render() {
    if (this.state.error) {
      return <UI error={this.state.error} />
    }
    return this.props.children
  }
}
