import { cloneDeep } from 'lodash/lang'

import {
  SET_LOGIN_FORM,
  SET_LOGIN_FORM_FIELD
} from 'actions/login/forms/loginForm/actionTypes'

const reducer = (state = {}, action) => {
  switch (action.type) {
    case SET_LOGIN_FORM_FIELD:
      return Object.assign({}, state, { [action.field]: action.value })
    case SET_LOGIN_FORM:
      return cloneDeep(action.loginForm)
    default:
      return state
  }
}

export default reducer
