import React from 'react'

// local components
import BackupCareHeader from './BackupCareHeader'
import LegacyBackupCareHeader from './LegacyBackupCareHeader'
import EFSHeader from './EFSHeader'

const Header = props => {
  const { bhParam, bucCaseNumber } = props

  return (
    <>
      {bhParam === 'buc' && bucCaseNumber && (
        <BackupCareHeader caseNumber={bucCaseNumber} />
      )}
      {bhParam === 't' && <LegacyBackupCareHeader />}
      {bhParam === 'efs' && <EFSHeader />}
    </>
  )
}

export default Header
