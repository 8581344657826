import { compose } from 'redux'

import withMutation from './withMutation'
import withEventHandlers from './withEventHandlers'
import withRedux from './withRedux'
import withFormResults from './withFormResults'
import withState from './withState'

import UI from './UI'

export default compose(
  withRedux,
  withFormResults,
  withState, // for displaying logged-in state while loading account manager
  withMutation,
  withEventHandlers
)(UI)
