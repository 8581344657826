import React from 'react'

// material-ui
import Hidden from '@mui/material/Hidden'
import Box from '@mui/material/Box'

// components
import MobileHeader from './MobileHeader'
import DesktopHeader from './DesktopHeader'
import PrintHeader from './PrintHeader'

const LogoHeader = props => {
  return (
    <>
      <Hidden smUp>
        <MobileHeader {...props} />
      </Hidden>
      <Hidden smDown>
        <Box displayPrint='none'>
          <DesktopHeader {...props} />
        </Box>
        <Box display='none' displayPrint='block'>
          <PrintHeader {...props} />
        </Box>
      </Hidden>
    </>
  )
}

export default LogoHeader
