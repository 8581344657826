import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const scrollToTop = () => {
  try {
    // try newer API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'auto'
    })
  } catch (error) {
    // fallback for older browsers
    window.scrollTo(0, 0)
  }
}

// Via https://medium.com/@romanonthego/scroll-to-top-with-react-router-and-react-hooks-87ae21785d2f
// renders nothing, just attaches side effects
const withEffects = WrappedComponent => {
  return props => {
    const location = useLocation()
    const { pathname, search } = location

    // only run effect on pathname and/or search change
    useEffect(() => scrollToTop(), [pathname, search])

    // renders nothing
    return null
  }
}

export { scrollToTop }

export default withEffects
