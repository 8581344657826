import React from 'react'

import Box from '@mui/material/Box'

// components
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

const BrightHorizonsHeader = props => (
  <Box my={2}>
    <Typography my={2} align='center' variant='subtitle1'>
      * For a full list of locations and additional information,{' '}
      <Link
        underline='always'
        href='https://steveandkatescamp.com/bright-horizons-efs/'
        target='_blank'
      >
        check our EFS page
      </Link>
      , or email us at{' '}
      <Link href='mailto:letschat@steveandkate.com'>
        letschat@steveandkate.com
      </Link>
      .
    </Typography>
  </Box>
)

export default BrightHorizonsHeader
