import React from 'react'

// material ui
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

// components
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import FormCard from 'components/atoms/FormCard'
import AccountButton from 'components/atoms/AccountButton'
import FormTextFieldWithValidation from 'components/shared/FormTextFieldWithValidation'
import FormControlWithErrorHelper from 'components/shared/FormControlWithErrorHelper'

const LoginForm = props => {
  const {
    loginForm, // via redux state
    onChangeFormField, // via withEventHandlers
    onToggleCheckbox, // via withEventHandlers
    onClickLogIn, // via withEventHandlers
    mutationLoading, // via withMutation
    loggedIn, // via withState
    formErrors // via withFormResults/withState
  } = props

  const buttonText = mutationLoading ? 'Logging in...' : 'Log in'

  const buttonDisabled = () => {
    if (
      mutationLoading ||
      loggedIn ||
      !loginForm.email ||
      !loginForm.email.match(/^[^@\s]+@[^@\s]+$/) ||
      !loginForm.password ||
      loginForm.password.length < 8
    ) {
      return true
    }

    return false
  }

  return (
    <FormCard>
      <Typography my={[2, 4]} variant='h4' align='center'>
        Already have an account? Please log in.
      </Typography>
      <form>
        <FormTextFieldWithValidation
          label='Email'
          field='email'
          type='email'
          form={loginForm}
          onChange={onChangeFormField}
          errors={formErrors}
        />

        <FormTextFieldWithValidation
          label='Password'
          field='password'
          type='password'
          form={loginForm}
          onChange={onChangeFormField}
          errors={formErrors}
        />

        <FormControlWithErrorHelper
          errorsObject={formErrors}
          errorField='rememberMe'
        >
          <FormControlLabel
            value='rememberMe'
            checked={loginForm.rememberMe === 'true'}
            control={<Checkbox color='primary' />}
            onChange={onToggleCheckbox('rememberMe')}
            label={
              <Typography variant='subtitle1' color='text.secondary'>
                Stay signed in
              </Typography>
            }
            labelPlacement='end'
          />
        </FormControlWithErrorHelper>

        <Box mt={2} mb={1} align='center'>
          <AccountButton
            type='submit'
            variant='contained'
            color='secondary'
            disabled={buttonDisabled()}
            onClick={onClickLogIn}
          >
            {buttonText}
          </AccountButton>
        </Box>

        <Box mt={1} mb={2} align='center'>
          <Link
            align='center'
            color='text.secondary'
            variant='subtitle1'
            href='/password/new'
          >
            Forgot your password?
          </Link>
        </Box>
      </form>
    </FormCard>
  )
}

export default LoginForm
