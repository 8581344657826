import { combineReducers } from 'redux'

import forms from './forms'
import modal from 'reducers/account/modal'
import errorMessage from 'reducers/account/errorMessage'
import notifications from 'reducers/account/notifications'

const rootReducer = combineReducers({
  forms,
  modal,
  errorMessage,
  notifications
})

export default rootReducer
