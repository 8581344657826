import React from 'react'

// material UI
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'

const StyledButton = styled(Button)(({ theme }) => ({
  paddingTop: 0,
  paddingBottom: 0,
  textTransform: 'none',
  fontStyle: 'italic',
  marginBottom: theme.spacing(1),
  marginTop: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  boxShadow: theme.shadows[0]
}))

// const POSITIONS = {
//   left: {
//     xs: { mt: 0, ml: 0 },
//     sm: { mt: 1, ml: 1 }
//   },
//   right: { ml: { xs: 0, sm: 1 } },
//   null: {}
// }

const AccountButton = props => {
  // const { position } = props
  // const sx = POSITIONS[position]

  return <StyledButton {...props} />
}

export default AccountButton
