import React from 'react'

// components
import MobileLogoHeader from 'components/shared/LogoHeader/MobileHeader'

const MobileBanner = props => {
  const { title } = props

  return <MobileLogoHeader title={title} />
}

export default MobileBanner
