import React from 'react'

// local components
import BackupCareFooter from './BackupCareFooter'
import EFSFooter from './EFSFooter'

const Footer = props => {
  const { bhParam } = props

  return (
    <>
      {bhParam === 't' && <BackupCareFooter />}
      {bhParam === 'efs' && <EFSFooter />}
    </>
  )
}

export default Footer
