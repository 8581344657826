// Note: We could pass this from rails
// however, for faster loading, this is stored once here

// Mailing address
export const COMPANY_NAME = "Steve & Kate's Camp"
export const COMPANY_ADDRESS = '58 West Portal Ave #800'
export const COMPANY_CITY = 'San Francisco'
export const COMPANY_STATE = 'CA'
export const COMPANY_ZIP_CODE = '94127'
export const COMPANY_COUNTRY = 'USA'
export const COMPANY_PHONE = '415-389-5437'

// Tax payer identification
export const TID_NUMBER = '04-2949680'
export const TID_NAME = "Bright Horizons Children's Centers LLC"
export const TID_ADDRESS = '2 Wells Ave'
export const TID_CITY = 'Newton'
export const TID_STATE = 'MA'
export const TID_ZIP_CODE = '02459'
