import { createTheme } from '@mui/material/styles'

const parentTheme = theme =>
  createTheme({
    ...theme,
    typography: {
      ...theme.typography,
      h1: {
        ...theme.typography.h1,
        textTransform: 'uppercase',
        fontWeight: 900
      },
      h2: {
        ...theme.typography.h2,
        textTransform: 'uppercase',
        fontWeight: 900
      },
      h3: {
        ...theme.typography.h3,
        textTransform: 'uppercase',
        fontWeight: 700
      },
      h4: {
        ...theme.typography.h4,
        fontWeight: 700
      },
      h5: {
        ...theme.typography.h5,
        fontWeight: 700
      },
      h6: {
        ...theme.typography.h6,
        textTransform: 'uppercase',
        fontWeight: 700
      }
    },
    zIndex: {
      // Olark widget z-index is 9000000. This one goes to 11!
      snackbar: 9000011
    }
  })

export default parentTheme
