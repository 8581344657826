import React from 'react'

// material-ui
import Box from '@mui/material/Box'

// logo
import Skc from 'uno/images/svg/skc.svg'
import { styled } from '@mui/material/styles'
import { compose, spacing, sizing, positions } from '@mui/system'

import Typography from '@mui/material/Typography'

const HeaderLogo = styled(Skc)(compose(spacing, sizing, positions))

const DesktopHeader = props => {
  const { title } = props
  return (
    <Box width='100%' height='5rem' mb='.5rem'>
      <HeaderLogo ml='2rem' position='absolute' width='20%' maxWidth='180px' />
    </Box>
  )
}

export default DesktopHeader
