import React from 'react'

// material-ui
import Box from '@mui/material/Box'

// logo
import Skc from 'uno/images/svg/skc.svg'
import { styled } from '@mui/material/styles'
import { compose, spacing, sizing, positions } from '@mui/system'

import Typography from '@mui/material/Typography'

const HeaderLogo = styled(Skc)(compose(spacing, sizing, positions))

const DesktopHeader = props => {
  const { title, variant } = props
  return (
    <Box width='100%' height='11rem' mb='.5rem' color='white' bgcolor='black'>
      <HeaderLogo
        sx={{
          position: 'absolute',
          ml: { sm: '2rem', md: '4rem' },
          mt: '1rem',
          width: '25%',
          maxWidth: { sm: '140px', md: '180px' }
        }}
      />

      <Typography
        maxWidth='50%'
        pt={8}
        mx='auto'
        align='center'
        variant={variant || 'h1'}
      >
        {title}
      </Typography>
    </Box>
  )
}

export default DesktopHeader
