import { createTheme } from '@mui/material/styles'
import { deepmerge } from '@mui/utils'

const defaultTheme = createTheme()

// CHOICES:
const palette = {
  limeGreen: '#8dc63f',
  rightNowRed: '#be1e2d',
  wrongRed: '#F44336',
  helloYellow: '#f8c440',
  black: '#000',
  white: '#fff',
  // kid world:
  popPurple: '#652d90',
  stopmeFuschia: '#be02c2',
  bandstandOrange: '#f15a24',
  orangeCrush: '#f7931e'
}

const fontFamily = {
  main: ['brandon-grotesque', 'sans-serif'].join(', ')
}

// DECISIONS:
const unoTheme = createTheme({
  breakpoints: {
    // material-ui defaults:
    // xs, extra-small: 0px
    // sm, small: 600px
    // md, medium: 900px
    // lg, large: 1200px
    // xl, extra-large: 1536px
  },
  palette: {
    // color intentions:
    primary: {
      main: palette.black,
      contrastText: palette.white
    },
    secondary: {
      main: palette.limeGreen,
      contrastText: palette.white
    },
    success: {
      main: palette.limeGreen,
      contrastText: palette.white
    },
    info: {
      main: '#e0e0e0'
    },
    warning: {
      main: palette.helloYellow
    },
    error: {
      main: palette.wrongRed
    },
    background: {
      default: '#fafafa'
    }
  },
  typography: {
    // fontSize: '0.875rem',
    fontFamily: fontFamily.main,
    h1: {
      fontFamily: fontFamily.main,
      marginTop: 0,
      marginBottom: '.6rem',
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '26px',
        lineHeight: 1.25
      },
      [defaultTheme.breakpoints.up('sm')]: {
        fontSize: '32px',
        lineHeight: 1.125
      },
      [defaultTheme.breakpoints.up('md')]: {
        fontSize: '41px',
        lineHeight: 1.125
      },
      [defaultTheme.breakpoints.up('lg')]: {
        fontSize: '46px',
        lineHeight: 1.125
      }
    },
    h2: {
      fontFamily: fontFamily.main,
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '26px',
        lineHeight: 1.25
      },
      [defaultTheme.breakpoints.up('sm')]: {
        fontSize: '28px',
        lineHeight: 1.25
      },
      [defaultTheme.breakpoints.up('md')]: {
        fontSize: '32px',
        lineHeight: 1.25
      },
      [defaultTheme.breakpoints.up('lg')]: {
        fontSize: '36px',
        lineHeight: 1.125
      }
    },
    h3: {
      fontFamily: fontFamily.main,
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '20px',
        lineHeight: 1.25
      },
      [defaultTheme.breakpoints.up('md')]: {
        fontSize: '22px',
        lineHeight: 1.25
      },
      [defaultTheme.breakpoints.up('lg')]: {
        fontSize: '26px',
        lineHeight: 1.25
      },
      [defaultTheme.breakpoints.up('xl')]: {
        fontSize: '28px',
        lineHeight: 1.125
      }
    },
    h4: {
      fontFamily: fontFamily.main,
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '18px',
        lineHeight: 1.25
      },
      [defaultTheme.breakpoints.up('md')]: {
        fontSize: '20px',
        lineHeight: 1.25
      },
      [defaultTheme.breakpoints.up('lg')]: {
        fontSize: '22px',
        lineHeight: 1.25
      },
      [defaultTheme.breakpoints.up('xl')]: {
        fontSize: '26px',
        lineHeight: 1.125
      }
    },
    h5: {
      fontFamily: fontFamily.main,
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '16px',
        lineHeight: 1.25
      },
      [defaultTheme.breakpoints.up('md')]: {
        fontSize: '18px',
        lineHeight: 1.25
      },
      [defaultTheme.breakpoints.up('lg')]: {
        fontSize: '20px',
        lineHeight: 1.25
      },
      [defaultTheme.breakpoints.up('xl')]: {
        fontSize: '22px',
        lineHeight: 1.125
      }
    },
    h6: {
      fontFamily: fontFamily.main,
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '12px',
        lineHeight: 1.25
      },
      [defaultTheme.breakpoints.up('md')]: {
        fontSize: '12px',
        lineHeight: 1.25
      },
      [defaultTheme.breakpoints.up('lg')]: {
        fontSize: '14px',
        lineHeight: 1.25
      },
      [defaultTheme.breakpoints.up('xl')]: {
        fontSize: '14px',
        lineHeight: 1.125
      }
    },
    subtitle1: {
      fontFamily: fontFamily.main
    },
    subtitle2: {
      fontFamily: fontFamily.main
    },
    body1: {
      fontFamily: fontFamily.main,
      // uno/variables/typography.scss
      [defaultTheme.breakpoints.down('lg')]: {
        fontSize: '18px',
        lineHeight: 1.3
      },
      [defaultTheme.breakpoints.up('lg')]: {
        fontSize: '20px',
        lineHeight: 1.3
      }
    },
    body2: {
      fontFamily: fontFamily.main
    },
    button: {
      fontFamily: fontFamily.main,
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '18px'
      },
      [defaultTheme.breakpoints.up('sm')]: {
        fontSize: '20px'
      },
      [defaultTheme.breakpoints.up('md')]: {
        fontSize: '22px'
      }
    },
    caption: {
      fontFamily: fontFamily.main
    },
    overline: {
      fontFamily: fontFamily.main
    }
  },
  shape: {
    borderRadius: 0
  },
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          fontSize: '16px',
          lineHeight: 1.3
        },
        filledSuccess: {
          color: 'white'
        }
      }
    }
  }
})

export default deepmerge(defaultTheme, unoTheme)
