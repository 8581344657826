import {
  SET_ERROR_MESSAGE,
  SET_MODAL,
  NOTIFY,
  CLEAR_NOTIFICATION
} from './actionTypes'

export const setErrorMessage = (message, opts) => {
  const { variant = 'error' } = opts || {}
  return {
    type: SET_ERROR_MESSAGE,
    message,
    variant
  }
}

export const setModal = ({ id, open }) => {
  return {
    type: SET_MODAL,
    id,
    open
  }
}

export const notify = ({ variant, message, autoHide }) => {
  return {
    type: NOTIFY,
    variant,
    autoHide,
    message
  }
}

export const clearNotification = ({ id }) => {
  return {
    type: CLEAR_NOTIFICATION,
    id
  }
}
