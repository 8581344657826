import { connect } from 'react-redux'
import { clearNotification } from 'actions/account'

const mapStateToProps = ({ notifications }) => ({ notifications })
const actions = { clearNotification }

const withRedux = connect(
  mapStateToProps,
  actions
)

export default withRedux
