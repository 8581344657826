import { compose } from 'redux'

// higher-order components
import withRedux from './withRedux'
import withState from './withState'
import withQueue from './withQueue'
import withEventHandlers from './withEventHandlers'

// presentational component
import UI from './UI'

export default compose(withRedux, withState, withQueue, withEventHandlers)(UI)
