import React, { useState } from 'react'

const withState = WrappedComponent => {
  return props => {
    const [loggedIn, setLoggedIn] = useState(false)

    return (
      <WrappedComponent
        loggedIn={loggedIn}
        setLoggedIn={setLoggedIn}
        {...props}
      />
    )
  }
}

export default withState
