import React from 'react'

const withEventHandlers = WrappedComponent => {
  return props => {
    const {
      notification, // via redux state
      clearNotification, // via redux actions
      processQueue, // via withQueue
      setOpen, // via withState
      setClosed // via withState
    } = props

    // When snackbar starts closing (either manually dismissed or automatically)
    // remove the current notification from the Redux queue and close the
    // snackbar (the notification stays in local state so it can fade out)
    const handleClose = (event, reason) => {
      clearNotification(notification)
      setOpen(false)
    }

    // when snackbar finishes closing, display the next notification
    const handleExited = () => {
      setClosed(true)
      processQueue()
    }

    return (
      <WrappedComponent
        onClose={handleClose}
        onExited={handleExited}
        {...props}
      />
    )
  }
}

export default withEventHandlers
