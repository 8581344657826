import withFormResults from 'components/shared/withFormResults'

export default withFormResults({
  mutation: 'logInAccount',
  field: 'account',
  successMessage: 'Login successful! Loading…',
  onSuccess: data => {
    window && (window.location.pathname = '/account')
  }
})
