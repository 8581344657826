import { connect } from 'react-redux'
import { setErrorMessage } from 'actions/account'

const mapStateToProps = ({ errorMessage }) => ({
  message: errorMessage?.message,
  variant: errorMessage?.variant
})

const actions = { setErrorMessage }

const withRedux = connect(mapStateToProps, actions)

export default withRedux
