import React from 'react'

// material-ui
import Hidden from '@mui/material/Hidden'

// components
import Mobile from './Mobile'
import Desktop from './Desktop'

const Banner = ({ headerMessage }) => {
  return (
    <>
      <Hidden smUp>
        <Mobile title={headerMessage} />
      </Hidden>
      <Hidden smDown>
        <Desktop title={headerMessage} />
      </Hidden>
    </>
  )
}

export default Banner
