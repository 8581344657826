import React from 'react'
import ReactDOM from 'react-dom'

import App from 'components/login/App'

document.addEventListener('DOMContentLoaded', () => {
  const element = document.getElementById('root')
  const props = JSON.parse(element.dataset.props)

  ReactDOM.render(<App {...props} />, element)
})
