import React, { useState } from 'react'

const withState = WrappedComponent => {
  return props => {
    const [open, setOpen] = useState(false)
    const [closed, setClosed] = useState(true)

    // get one notification at a time from the redux queue for display
    const [notification, setNotification] = React.useState(undefined)

    return (
      <WrappedComponent
        open={open}
        setOpen={setOpen}
        closed={closed}
        setClosed={setClosed}
        notification={notification}
        setNotification={setNotification}
        {...props}
      />
    )
  }
}

export default withState
