import React from 'react'

// material ui
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

// components
import ErrorMessage from 'components/shared/ErrorMessage'
import AccountNotice from 'components/shared/AccountNotice'

// local components
import Header from './Header'
import CreateAccount from './CreateAccount'
import Form from './Form'
import Footer from './Footer'

const Login = props => {
  const { notice, bhParam, bucCaseNumber } = props

  return (
    <>
      <Container maxWidth='md'>
        <ErrorMessage />
        {notice && <AccountNotice notice={notice} />}
        <Header bhParam={bhParam} bucCaseNumber={bucCaseNumber} />
        <Box mt={4}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Form />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CreateAccount />
            </Grid>
          </Grid>
        </Box>
        <Footer bhParam={bhParam} />
      </Container>
    </>
  )
}

export default Login
