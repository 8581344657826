import React from 'react'

import Box from '@mui/material/Box'

// components
import Typography from '@mui/material/Typography'

const BackupCareHeader = ({ caseNumber }) => (
  <Box my={2}>
    <Typography my={2} align='center' variant='h4'>
      Log in or create an account to activate your Back-Up Care reservation{' '}
      {caseNumber}.
    </Typography>
  </Box>
)

export default BackupCareHeader
