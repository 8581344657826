import { connect } from 'react-redux'
import { setErrorMessage, notify } from 'actions/account'

const mapStateToProps = () => ({})

const withRedux = connect(
  mapStateToProps,
  { setErrorMessage, notify }
)

export default withRedux
