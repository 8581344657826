import { connect } from 'react-redux'
import { setErrorMessage } from 'actions/account'
import { setLoginFormField, setLoginForm } from 'actions/login/forms/loginForm'

const mapStateToProps = ({ forms: { loginForm } }) => ({ loginForm })

const actions = { setLoginFormField, setLoginForm, setErrorMessage }

const withRedux = connect(
  mapStateToProps,
  actions
)

export default withRedux
