import React from 'react'

import Box from '@mui/material/Box'

// components
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

const BackupCareFooter = props => (
  <Box my={2}>
    <Typography my={2} align='center' variant='subtitle1'>
      * Didn't receive passcodes yet? Email{' '}
      <Link href='mailto:letschat@steveandkate.com'>
        letschat@steveandkate.com
      </Link>{' '}
      (include your Bright Horizons case number).
    </Typography>
  </Box>
)

export default BackupCareFooter
