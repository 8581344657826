import React from 'react'

// material ui layout
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'

/**
Provides a card that wraps the contents of a form.
**/
const FormCard = ({ children }) => {
  return (
    <Card elevation={1}>
      <CardContent>
        <Box px={[1, 2]}>{children}</Box>
      </CardContent>
    </Card>
  )
}

export default FormCard
