import React from 'react'

// material ui
import Box from '@mui/material/Box'

// components
import Typography from '@mui/material/Typography'
import AccountButton from 'components/atoms/AccountButton'
import FormCard from 'components/atoms/FormCard'

const CreateAccount = props => {
  return (
    <FormCard>
      <Typography my={[2, 4]} variant='h4' align='center'>
        New to Steve &amp; Kate's?
      </Typography>
      <Box align='center'>
        <AccountButton variant='contained' color='primary' href='/register'>
          Create an account
        </AccountButton>
      </Box>
      <Typography my={[2, 4]} variant='subtitle1' align='center'>
        Completing registration will take 5-10 min. We ask for a lot of info
        upfront so you don't have to worry about forms when you arrive at camp.
      </Typography>
    </FormCard>
  )
}

export default CreateAccount
